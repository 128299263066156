body{
  margin: 0;
  padding: 0;
}

.ruluko {
  font-family: 'Ruluko';
}

.roboto {
  font-family: 'Roboto';
}

.belanosima {
  font-family: 'Belanosima';
}

.div-presentation {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.img-presentation {
  width: 500px;
}

.div-header-text {
  padding: 0 5% 0 40%;
  justify-content: center;
}

.button-schedule {
  align-items: center;
  background: linear-gradient(90deg, rgba(128,16,116,1) 0%, rgba(6,172,33,1) 100%);
  color: white;
  font-weight: bold;
  font-size: 24px;
  padding: 15px;
  margin: 5% 20%;
  border: none;
  border-radius: 8px;
  width: 50%;
  opacity: 1;
}

.button-schedule:hover {
  opacity: 0.7;
  cursor:pointer;
}

.div-header-image {
  background-color: rgba(242,238,242,255);
}

.div-segments {
  background-color: rgb(128, 16, 116);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  padding: 0 20%;
}

.button-segment {
  background: linear-gradient(90deg, rgba(128,16,116,1) 0%, rgba(6,172,33,1) 100%);
  color: white;
  border: none;
  padding: 5% 10%;
  margin: 5%;
  border-radius: 8px;
  height: 70%;
  font-size: 20px;
  font-weight: bold;
}

.img-cropped-detail {
  width: 300px;
}

.div-reasoning {
  display: grid;
  grid-template-columns: 0.4fr 1.6fr 0.4fr;
  padding: 75px 0;
}

.div-centralized-content {
  margin: 0 10%;
}

.h1-centralized-content {
  color: rgb(128, 16, 116);
  font-weight: 900;
  font-size: 60px;
  padding: 0 20%;
  text-align: center;
}

.ul-habits {
  list-style-type: none;
  background-color: rgb(241, 237, 241);
  border-radius: 30px;
  padding: 5% 10%;
  font-size: 36px;
}

.div-about {
  display: grid;
  grid-template-columns: 0.8fr 1.2fr;
  padding: 0 10%;
}

.img-profile {
  max-width: 600px;
}

.img-profile-mobile {
  display: none;
}

.div-about-presentation {
  padding: 15% 0;
  
}

.h1-presentation-header {
  font-size: 48px;
  margin: 0;
}

.h1-presentation-description {
  font-weight: 100;
  padding: 1% 0 0 0;
  font-size: 32px;
}

.div-results {
  background-color: rgb(242, 242, 242);
  padding: 5% 0 0 0;
  align-items: center;
  text-align: center;
}

.div-results-box {
  background-color: white;
  border-radius: 20px;
  padding: 1% 0;
}

.h1-subtitle-box {
  background-color: rgb(6, 172, 33);
}

.img-feedback {
  max-width: 1200px;
}

.button-subtitle {
  align-items: center;
  background: rgb(6, 172, 33);
  color: white;
  font-weight: bold;
  font-size: 30px;
  padding: 7px 15px;
  margin: 0 0 2% 0;
  border: none;
  border-radius: 8px;
  width: 50%;
}

.img-presentation-cropped {
  display: none;
}

.button-schedule-results {
  align-items: center;
  background: linear-gradient(90deg, rgba(128,16,116,1) 0%, rgba(6,172,33,1) 100%);
  color: white;
  font-weight: bold;
  font-size: 24px;
  padding: 15px;
  margin: 0 0 3% 0;
  border: none;
  border-radius: 8px;
  width: 30%;
  opacity: 1;
}

.button-schedule-results:hover {
  opacity: 0.7;
  cursor:pointer;
}

.div-blog-items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  padding: 0 10%;
}

.button-news {
  margin: 0 1px;
  padding: 0 7%;
  border-radius: 15px;
  background-color: white;
  border-color: black;
  min-height: 600px;
  max-height: 600px;
  min-width: 270px;
  max-width: 400px;
  align-items: start;
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
}

.h1-news {
  text-align: left;
  padding: 0 5%;
  font-weight: 900;
}

.img-blog-article {
  width: 100%;
  border-radius: 15px;
  margin: 30px 0;
  display: cover;
  max-height: 200px;
}

.button-read-more {
  align-items: center;
  background: rgb(6, 172, 33);
  color: white;
  font-weight: bold;
  font-size: 25px;
  padding: 5px 5px;
  margin: 0 0 2% 0;
  border-radius: 15px;
  border-style: none;
  border: none;
  width: 70%;
  opacity: 1;
}

.button-read-more:hover {
  opacity: 0.7;
  cursor: pointer;
}


.h1-blog-description {
  padding: 3% 20%;
  text-align: center;
  font-weight: 400;
}

.button-articles {
  align-items: center;
  background: linear-gradient(90deg, rgba(128,16,116,1) 0%, rgba(6,172,33,1) 100%);
  color: white;
  font-weight: bold;
  font-size: 30px;
  padding: 15px;
  margin: 0 0 3% 0;
  border: none;
  border-radius: 8px;
  width: 20%;
  opacity: 1;
}

.button-articles:hover {
  opacity: 0.7;
  cursor: pointer;
}

.div-footer-information {
  text-align: center;
  align-items: center;
  display: block;
  max-height: 800px;
}

.div-footer {
  background-image: url("../images/profile-3.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}



.h1-footer {
  padding: 10% 15% 0 15%;
  text-align: center;
  font-size: 64px;
  color: white;
}

.button-instagram {
  font-size: 24px;
  border-radius: 40px;
  height: 72px;
  width: 380px;
  background: url('../images/instagram.png') no-repeat;
  cursor: pointer;
  opacity: 1;
}

.button-instagram:hover {
  opacity: 0.7;
}

.button-whatsapp {

  font-size: 24px;
  border-radius: 40px;
  height: 72px;
  width: 380px;
  background: url('../images/whatsapp-button.png') no-repeat;
  cursor: pointer;
  opacity: 1;
}

.button-whatsapp:hover {
  opacity: 0.7;
}

.h1-footer-message {
  color: white;
  font-weight: 400;
  font-size: 36px;
  padding: 10% 10% 0 10%;
}

.img-footer {
  display: none;
}

.img-logo {
  width: 300px;
  display: none
}

.img-logo-blog {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.img-logo-cropped {
  padding: 20px 0 0 45px;
  width: 330px;  
}

.h1-subheader {
  font-weight: 400;
  font-size: 36px;
}

/* @media (max-width: 768px) {
  .img-feedback {
    width: 100%;
  }
} */

@media (max-width: 820px) {
  .div-presentation {
    padding: 0;
    margin: 0;
    display: inline;
    max-width: 768px;
  }

  /* .img-logo {
  } */

  .img-logo-blog {
    padding: 0;
    align-items: center;
  }

  .img-logo-cropped {
    display: inline;
    width: 180px;
    padding: 5px 0 0 0;
  }

  .h1-presentation {
    padding: 0 2%;
    margin: 2% 0;
  }

  .div-header-text {
    padding: 0;
    margin: 0;
    display: block;
  }

  .img-logo {
    display: none;
  }

  .button-schedule {
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    width: 50%;
  }

  .img-presentation {
    display: none;
  }

  .button-segment {
    font-size: 10px;
    padding: 30px;
    vertical-align: center;
  }

  .img-cropped-detail {
    display: none;
  }

  .h1-centralized-content {
    padding: 0 20px;
    font-size: 30px;
    text-align: center;
  }

  .img-profile {
    display: none;
  }

  .h1-presentation-header {
    font-size: 30px;
    /* padding: 0 25%; */
    text-align: center;
  }

  .img-profile-mobile {
    display: block;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
  }

  .ul-habits {
    font-size: 20px;
  }

  .img-blog-article {
    max-width: 250px;
  } 

  .h1-news {
    display: none;
  }

  .div-about-presentation {
    padding: 10% 10%;
  }

  .div-blog-items {
    padding: 0 15px;
    display: inline;
  }

  .h1-blog-description {
    display: none;
  }

  .button-articles {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .button-schedule-results {
    width: 250px;
  }

  .div-footer {
    background-image: none;
    color: rgba(128,16,116,1);
    display: block
  }

  .img-footer {
    display: inline;
    width: 100%;
  }

  .button-news {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    margin-bottom: 2%;
  }

  .h1-footer {
    color: rgba(128,16,116,1);
    font-size: 24px;
  }



  .h1-footer-message{
    color: rgba(128,16,116,1);
    font-size: 30px;
  }

  .img-presentation-cropped {
    display: block;
    width: 50%;
    /* margin: 0;
    padding: 0 25% 0 25%; */
    margin-left: auto;
    margin-right: auto;
  }

  .h1-presentation-description {
    font-size: 24px;
    padding: 0 10px;
  }

  .img-feedback {
    width: 95%;
  }

  .button-subtitle {
    font-size: 20px;
    margin: 10px 0;
    align-items: center;
    background: rgb(6, 172, 33);
    color: white;
    font-weight: bold;
    padding: 7px 7px;
    margin: 0 0 2% 0;
    width: 80%;
  }

  .div-about {
    display: inline;
    padding: 0 10px;
  }

  .div-reasoning {
    display: inline;
  }

  .button-read-more {
    font-size: 15px;
    font-weight: 400;
    width: 90%;
    margin: 18px 0 0 0;
  }
}

.ruluko {
  font-family: 'Ruluko';
}

.roboto {
  font-family: 'Roboto';
}

.belanosima {
  font-family: 'Belanosima';
}

.h1-header {
  margin: 100px 0 0 0;
  color: #801074;
}

.h1-header-blog {
  margin: 100px 0 0 0;
  color: #801074;
  font-size: 45px;
}

.div-blog-items-blog {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  padding: 0 20%;
  
}

.button-news-blog {
  margin: 10px;
  padding: 0 7%;
  border-radius: 15px;
  background-color: white;
  border-color: black;
  min-height: 600px;
  max-height: 1200px;
  min-width: 480px;
  align-items: start;
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
}

.button-read-more {
  align-items: center;
  background: rgb(6, 172, 33);
  color: white;
  font-weight: bold;
  font-size: 25px;
  padding: 5px 5px;
  margin: 0 0 2% 0;
  border-radius: 15px;
  border-style: none;
  width: 70%;
  opacity: 1;
}

.button-read-more:hover {
  opacity: 0.7;
  cursor: pointer;
}

.div-news-container {
  margin: 3% 10%;
  padding: 3% 3%;
  border-style: solid;
  border-color: #801074;
  border-width: 1px;
  border-radius: 20px;
}

.h1-news-title {
  text-align: center;
}

.img-center {
  width: 400px;
  border-radius: 15px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.header {
  display: grid;
  grid-template-columns: 1fr 1.5fr 0.5fr;

}

.img-first-news {
  max-width: 450px;
  border-radius: 15px;
}

.img-first-news-blog {
  max-width: 380px;
  border-radius: 15px;
}

.whatsapp-float {
  position: fixed;
  bottom: 40px;
  right: 20px;
}

.header-popup {
  font-size: 28px;
  text-align: center;
}

.button-close {
  width: 40px;
  height: 40px;
  border-radius: 25px;
  font-size: 30px
}

.popup-content {
  min-width: 810px;
}

.img-instagram-header {
  max-width: auto;
}

@media (max-width: 820px) {
  .h1-header-blog {
    text-align: center;
    margin: 0;
  }

  .popup-content {
    min-width: 350px;
    max-width: 350px;
  }

  .modal {
    max-width: 350px;
    max-height: 800px;
  }

  .img-instagram-header {
    max-width: 330px;
  }

  .whatsapp-float {
    bottom: 10%;
    right: 10%;
  }

  .header {
    display: inline;
    grid-template-columns: none;
  }

  .div-footer-information {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .h1-header {
    margin: 10px;
    text-align: center;
  }

  .div-blog-items {
    padding: 0;
  }

  .div-blog-items-blog {
    display: inline;
  }

  .img-first-news {
    width: 300px;
  }

  .h1-news {
    display: none;
  }

  .button-news-blog {
    display: block;
    margin-left: auto;
    margin-right: auto;
    min-width: 80%;
    max-width: 90%;
  }

  .img-center {
    max-width: 240px;
  }

  .img-first-news-blog {
    min-width: 80%;
    max-width: 90%;
  }
}